<template>
  <div id="hotGame" class="original">
    <div class="hotgame_box max-width">
      <div class="hotgame_title">热门游戏</div>
      <div class="hotgame_content">
        <ul class="hotgame_list hand" v-for="(item, index) in hotGameList" :key="index"
          @click="download(item.gw2, item)">
          <li class="hotgame_li">
            <div class="list_img" @click="">
              <!-- <img src="../../assets/image/pc/hotGame/1.jpg" alt /> -->
              <a>
                <img :src="item.img" alt />
              </a>
              <!-- <div class="cd_box">
              <div class="cord_img">二维码</div>
              <div class="dload_box">
                <div class="title_box">{{ item.head }}</div>
                <div class="dl_img">
                  <img src="../../assets/image/icon/21.png" alt />
                </div>
                <div class="dl_text">立即下载</div>
              </div>
            </div> -->
            </div>
            <div class="list_content">
              <div class="title_box">{{ item.head }}</div>
              <div class="descibe_box">
                <div class="version" style="padding-left: 0.04rem">
                  版本：{{ item.version }}
                </div>
                <div class="newOpen" style="padding-left: 0.03rem">
                  上线时间：{{ item.time }}
                </div>
                <div class="timeOpen" style="padding-left: 0.03rem">
                  开服时间：{{ item.newOpen }}
                </div>
              </div>
              <div class="gw">
                <a style="padding-left: 0.01rem">官网：{{ item.gw }}</a>
              </div>
            </div>
          </li>
        </ul>
        <div class="hotgame_list jqqd">敬请期待...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      currentIndex: -1,
      hotGameList: [
        {
          isShow: "https://xqn.3975qn.com/",
          img: require("@/assets/image/pc/hotGame/9.jpg"),
          head: "《新千年-正版授权》",
          version: "绿色版",
          time: "",
          newOpen: "限号内侧中",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/500177.png"),
          head: "《千年盛世重返版》",
          version: "二层版本",
          time: "2025.1.6",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/500223.png"),
          head: "《千年盛世天空版》",
          version: "一层版本",
          time: "2025.1.4",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/500228.png"),
          head: "《三界天骄》",
          version: "二层版本",
          time: "202.12.7",
          newOpen: "2025.1.3",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/500233.png"),
          head: "《烽火千年》",
          version: "一层版本",
          time: "2025.1.4",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/500234.png"),
          head: "《暮颜》",
          version: "二层版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/236.png"),
          head: "《星耀千年》",
          version: "二层版本",
          time: "2024.11.2",
          newOpen: "2024.12.19",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/248.jpg"),
          head: "《枭雄天下》",
          version: "一层版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/254.png"),
          head: "《元神千年》",
          version: "二层版本",
          time: "2024.12.13",
          newOpen: "2024.12.21",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/mhqn.jpg"),
          head: "《梦幻千年》",
          version: "二层版本",
          time: "2024.7.22",
          newOpen: "",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/bxqn.png"),
          head: "《冰雪千年》",
          version: "独家版本",
          time: "2024.6.29",
          newOpen: "2024.6.29",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/rmjsmj.png"),
          head: "《绝世秘籍》",
          version: "一层版本",
          time: "2024.8.31",
          newOpen: "2024.9.10",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/rmxaqn.png"),
          head: "《笑傲千年》",
          version: "二层版本",
          time: "2024.10.26",
          newOpen: "2024.11.2",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/zjsrm.png"),
          head: "《铸剑师》",
          version: "二层版本",
          time: "",
          newOpen: "2024.10.7",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/zhtxrm.png"),
          head: "《千年盛世之畅爽版》",
          version: "二层版本",
          time: "2024.10.19",
          newOpen: "2024.10.24",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/rmzjjh.png"),
          head: "《仗剑江湖》",
          version: "二层版本",
          time: "2024.9.27",
          newOpen: "2024.10.2",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/qxz.png"),
          head: "《群侠传》",
          version: "一层版本",
          time: "2024.8.7",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyqn.png"),
          head: "《九妖千年》",
          version: "高倍怀旧版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/ltzj.png"),
          head: "《戮天之剑》",
          version: "二层版本",
          time: "2024.5.10",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/rx.png"),
          head: "《热血Online》",
          version: "一层版本",
          time: "2024.6.7",
          newOpen: "2024.6.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jjgc.png"),
          head: "《精绝古城》",
          version: "绿色版",
          time: "2024.7.16",
          newOpen: "2024.7.20",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/qnwc.png"),
          head: "《千年王朝》",
          version: "一层版本",
          time: "2024.7.6",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/7.jpg"),
          head: "《千年盛世之极纯千年》",
          version: "一层版本",
          time: "2023.12.1",
          newOpen: "2023.12.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/fs.jpg"),
          head: "《封神千年》",
          version: "一层版本",
          time: "2023.11.2",
          newOpen: "2023.11.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jj.jpg"),
          head: "《九剑魔龙传》",
          version: "二层版本",
          time: "2023.10.27",
          newOpen: "2023.11.3",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/fg.jpg"),
          head: "《复古千年》",
          version: "二层版本",
          time: "2023.11.9",
          newOpen: "2023.11.17",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/fyzq.jpg"),
          head: "《风云再起》",
          version: "一层版本",
          time: "2023.11.24",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/qngl.jpg"),
          head: "《千年归来》",
          version: "武功全自创版",
          time: "2023.11.29",
          newOpen: "2023.12.29",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyrg.png"),
          head: "《剑雨如歌》",
          version: "武功全自创版",
          time: "",
          newOpen: "2024.1.30",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xzd.jpg"),
          head: "《侠众道》",
          version: "二层版本",
          time: "2023.11.17",
          newOpen: "2024.2.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/szsd.png"),
          head: "《神州三端》",
          version: "一层版本",
          time: "",
          newOpen: "2024.2.2",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/lhcfjz.png"),
          head: "《离火重返激战》",
          version: "二层版本",
          time: "2024.4.22",
          newOpen: "2024.4.27",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xkxqn.png"),
          head: "《侠客行千年》",
          version: "三层版本",
          time: "",
          newOpen: "2024.3.14",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/dxts.png"),
          head: "《大侠坛说》",
          version: "一层版本",
          time: "2024.4.1",
          newOpen: "2024.4.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/jyssb.png"),
          head: "《九妖盛世版》",
          version: "二层版本",
          time: "2024.4.20",
          newOpen: "2024.4.30",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/pc/hotGame/zzbj.png"),
          head: "《诸子百家之千年武侠》",
          version: "二层版本",
          time: "2024.5.6",
          newOpen: "2024.5.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/lhqn.png"),
          head: "《问剑江湖》",
          version: "一层版本",
          time: "2024.4.26",
          newOpen: "2024.5.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/pc/hotGame/xss.png"),
          head: "《新盛世》",
          version: "二层版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
      ],
    };
  },
  created() { },
  mounted() {
    // this.init()
  },
  watch: {},
  methods: {
    download(url, item) {
      let a = url
      if (item.isShow) {
        a = item.isShow
      }
      if (!a) {
        return;
      }
      downloadA(a)
    },
    downloadA,
    showbox(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.hand {
  cursor: pointer;
}

.max-width {
  max-width: 1920px;
  margin: 0 auto;
}

.original {
  height: auto;
  position: relative;
  z-index: 2;
  background-color: #0c0905;
  padding-bottom: 0.2rem;
}

a {
  color: #949494;
}

.nav_pop_enter {
  animation-name: slideDown;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  transform-origin: center top;
}

/* 定义一个动画 */
@keyframes slideDown {
  from {
    transform: scaleY(0);
    opacity: 0;
  }

  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.nav_pop_leave {
  animation-name: slideDownS;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  transform-origin: center top;
}

/* 定义一个动画 */
@keyframes slideDownS {
  from {
    transform: scaleY(1);
    opacity: 1;
  }

  to {
    transform: scaleY(0);
    opacity: 0;
  }
}

.hotgame_box {
  height: 100%;

  background: url(../../assets/image/pc/bg/142.jpg) no-repeat;
  // background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hotgame_title {
    width: 100%
      /* 160/192 */
    ;
    height: 0.916667rem
      /* 176/192 */
    ;
    text-align: center;
    line-height: 1.1rem
      /* 176/192 */
    ;
    // background: palegreen;
    font-size: 0.208333rem
      /* 40/192 */
    ;

    color: #f8f3e9;
    font-weight: bold;
  }

  .hotgame_content {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 6.3125rem
      /* 1212/192 */
    ;
    // height: 3.56875rem /* 666/192 */;
    // background: palevioletred;
    box-sizing: border-box;
    flex-wrap: wrap;

    li {
      position: relative;
      z-index: 1;
      width: 1.5rem
        /* 288/192 */
      ;
      height: 1.682292rem
        /* 323/192 */
      ;
      margin-left: 0.078rem
        /* 18/192 */
      ;
      margin-bottom: 0.078rem;
      background: #ffffff;
      border-radius: 0.052083rem
        /* 10/192 */
      ;
      opacity: 1;
      box-sizing: border-box;

      .list_img {
        width: 1.5rem
          /* 288/192 */
        ;
        height: 0.8125rem
          /* 156/192 */
        ;
        margin-bottom: 0.104167rem
          /* 20/192 */
        ;
        border-radius: 0.052083rem
          /* 10/192 */
          0.052083rem
          /* 10/192 */
          0 0;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.052083rem
            /* 10/192 */
            0.052083rem
            /* 10/192 */
            0 0;
        }
      }

      .list_img:hover {
        .cd_box {
          opacity: 1;
          height: 0.8125rem;
        }
      }

      .cd_box {
        overflow: hidden;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 1.5rem
          /* 288/192 */
        ;
        height: 0;
        transition: 0.4s;
        /* height: 0.8125rem; */
        box-sizing: border-box;
        margin-bottom: 0.104167rem;
        border-radius: 0.052083rem
          /* 10/192 */
          0.052083rem
          /* 10/192 */
          0rem
          /* 0/192 */
          0rem;
        background: rgba(204, 83, 65, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;

        /* animation-name: cd_avtS;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        transform-origin: center top; */
        .cord_img {
          width: 0.505208rem
            /* 97/192 */
          ;
          height: 0.505208rem
            /* 97/192 */
          ;
          background: #ffffff;
        }

        .dload_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .title_box {
            font-size: 0.083333rem
              /* 16/192 */
            ;
            margin-bottom: 0.088542rem
              /* 17/192 */
            ;
            color: #f6f5f4;
            font-weight: bold;
          }

          .dl_img {
            width: 0.177083rem
              /* 34/192 */
            ;
            height: 0.166667rem
              /* 32/192 */
            ;
            margin-bottom: 0.083333rem
              /* 16/192 */
            ;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .dl_text {
            color: #f6f5f4;
            font-weight: bold;
            font-size: 0.083333rem
              /* 16/192 */
            ;
          }
        }
      }

      .cd_avt {
        transition: all 0.6s ease-in-out;
        transform: translateY(0);
      }

      /* 定义一个动画 */
      @keyframes cd_avtS {
        from {
          transform: translateY(-0.8125rem);
          opacity: 0;
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .list_content {
        margin-left: 0.067708rem
          /* 13/192 */
        ;

        .title_box {
          font-weight: bold;
          color: #393333;
          font-size: 0.083333rem
            /* 16/192 */
          ;
          margin-bottom: 0.04167rem
            /* 20/192 */
          ;
        }

        .descibe_box {
          height: 0.25rem;
          line-height: 0.12rem;
          color: #231c0e;
          font-size: 0.072917rem
            /* 14/192 */
          ;
          margin-bottom: 0.167rem
            /* 20/192 */
          ;
          // padding-left: 0.04rem;
        }

        .gw {
          font-size: 0.072917rem
            /* 14/192 */
          ;
          color: #949494;
          height: 0.165625rem
            /* 51/192 */
          ;
          border-top: 0.005208rem
            /* 1/192 */
            solid #ececec;
          line-height: 0.1625rem
            /* 51/192 */
          ;
          padding-left: 0.03rem;
        }
      }
    }

    .jqqd {
      width: 1.5rem
        /* 288/192 */
      ;
      height: 1.682292rem
        /* 323/192 */
      ;
      text-align: center;
      line-height: 1.682292rem;
      font-size: 0.09375rem
        /* 18/192 */
      ;
      color: #f6f5f4;
    }
  }
}
</style>
