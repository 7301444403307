<template>
  <div id="wap" class="wap_app">
    <navBar @navChang="navChang" :activeRed="activeRed"></navBar>

    <div class="swiper wap_Swiper ">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <banner-view id="bannerView" @update="handleOpen"></banner-view>
        </div>
        <div class="swiper-slide">
          <new-center id="newCenter"></new-center>
        </div>
        <div class="swiper-slide">
          <hot-game id="hotGame"></hot-game>
        </div>
        <div class="swiper-slide">
          <new-game id="newGame"></new-game>
        </div>
        <div class="swiper-slide">
          <rank-game></rank-game>
        </div>
        <div class="swiper-slide">
          <about-us id="aboutUs"></about-us>
        </div>
        <div class="swiper-slide">
          <download id="downloadGame" @update="handleOpen"></download>
        </div>
      </div>

    </div>
    <div class="footer"></div>
    <!-- 引导页 -->
    <guide ref="guideopen"></guide>
  </div>
</template>
<script>
import navBar from "./wap_components/navBar";
import NewCenter from "./wap_components/newCenter.vue";
import BannerView from "./wap_components/bannerView";
import AboutUs from "./wap_components/AboutUs";
import download from "./wap_components/download";
import NewGame from "./wap_components/newGame.vue";
import RankGame from "./wap_components/rankGame.vue";
import HotGame from "./wap_components/hotGame";
import Guide from './guide/guide.vue';
import { qywx, WXTest, qq, iosModel } from "@/utils/guidance";
import { callApp, location } from "@/utils/download";
export default {
  components: {
    navBar,
    NewCenter,
    download,
    HotGame,
    // NewGame,
    // RankGame,
    AboutUs,
    // DownloadGame,
    BannerView,
    NewCenter,
    NewGame,
    RankGame,
    Guide,
  },
  data() {
    return {
      wapNavList: [],
      slideSwiper: null,
      activeRed: null,
      id: 0,
      sign: 0,
    };
  },
  created() { },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getHeight()
    })
  },
  methods: {
    getHeight() {
      const convertStyle = () => {
        document.box.style.setProperty('height', `${window.innerHeight}px`);
      }

      window.addEventListener("resize", convertStyle);
      window.addEventListener("DOMContentLoaded", convertStyle);

    },

    handleOpen(number, value) {
      if (qywx || qq || WXTest) {
        this.$refs.guideopen.show = true;
      } else if (number == 2) {
        location(PC_URL)
      } else {
        if (iosModel) {
          callApp(`${iosbag}=?type=universal&url/pages/index/index`, 2400).then().catch(() => {
            location(IOS_URL);
          })
        } else {
          try {
            callApp(`${Androidbag}=?type=universal&url/pages/index/index`, 2400).then().catch(() => {
              location(ANDROID_URL);
            })
          } catch (error) { }
        }
      }

    },
    // navlist (value) {
    //   this.wapNavList = value;
    // },
    navChang(id) {
      this.slideSwiper.slideTo(id);
      // console.log(id);
      if (id == 2) {
        this.slideSwiper.slideTo(id + 1);
      } else if (id == 3) {
        this.slideSwiper.slideTo(id + 2);
      } else if (id == 4) {
        this.slideSwiper.slideTo(id + 3);
      }
    },

    basescroll(id) {
      let dome = document.querySelector(id);
      dome.scrollIntoView();
    },
    wapSwiper() {
      let _this = this;
      _this.slideSwiper = new Swiper(".wap_Swiper", {
        direction: "vertical",
        // slidesOffsetBefore : 0,
        // loop:false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionStart: function () {
            _this.activeRed = this.activeIndex;
            // console.log(_this.activeRed, '_this.activeRed')
          },
        },
      });
    },
  },
  mounted() {
    let _this = this;
    this.$nextTick(() => {
      this.wapSwiper();
    });
  },
};
</script>

<style lang="scss" scoped>
// html {
//   height: -webkit-fill-available;
// }

.box {
  // min-height: 100vh;
  /* mobile viewport bug fix */
  // min-height:-webkit-fill-available
}

#wap {
  // position: fixed;
  // display: block;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

// .wap_app {
//   position: relative;
// }

.wap_Swiper {
  flex: 1;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  // flex-shrink: 0;
}

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }</style>
